@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:wght@700&family=Roboto:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.custom-underline::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #D966F9;
    transition: width .3s ease-in-out;
}

.custom-underline:hover::after {
    width: 100%;
}
